@import url('https://fonts.googleapis.com/css?family=Fira+Sans:400,500,600,700,800');
* {
  box-sizing: border-box;
}
body {
  min-height: 100vh;
  display: flex;
  background-color: #f5f5f5 !important;
  font-weight: 400;
  font-family: 'Fira Sans', sans-serif;
}
h1,
h2,
h3,
h4,
h5,
h6,
span {
  font-weight: 500;
  font-family: 'Fira Sans', sans-serif;
}
label {
  font-size: 14px;
  font-weight: 400;
}
textarea:focus,
input:focus,
select:focus {
  outline: none;
}
body,
html,
#root,
.auth-wrapper {
  width: 100%;
  height: 100%;
}
.navbar-light {
  background-color: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
}
.App {
  width: 99.9%;
  padding: 10px;
  padding-top: 5rem !important;
}
.auth-inner {
  width: 100%;
  margin: auto;
  /* padding: 40px; 
  border-radius: 15px;*/
  transition: all 0.3s;
}
.auth-wrapper .form-control:focus {
  border-color: #167bff;
  box-shadow: none;
}
.auth-wrapper h3 {
  text-align: center;
  margin: 0;
  line-height: 1;
  padding-bottom: 20px;
}
.custom-control-label {
  font-weight: 400;
}
.forgot-password,
.forgot-password a {
  text-align: right;
  font-size: 13px;
  padding-top: 10px;
  color: #7f7d7d;
  margin: 0;
}
.forgot-password a {
  color: #167bff;
}
/* Loader CSS */
.loading-box {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(79, 73, 79, 0.6);
  z-index: 99999;
}

.loader-logo {
  position: absolute;
  width: 50px;
  z-index: 99;
  -webkit-animation: greyscale 2s linear infinite;
  animation: greyscale 2s linear infinite;
}

.loader {
  border: 10px solid #f3f3f3;
  border-radius: 50%;
  border-top: 10px solid #1380c0;
  border-bottom: 10px solid #1380c0;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
  background-color: rgba(255, 255, 255, 0.69);
}
/* Loader CSS */

/* Header CSS */
.header-container {
  width: 100%;
  height: 3.95em;
  font-size: 18px;
  background: #1C1027 !important;
  position: fixed;
  top: 0;
  z-index: 100;
  display: flex;
  background-size: cover;
  
  padding: 5px 25px 5px 5px;
}
.logo-container {
  display: flex;
  cursor: pointer;
  object-fit: contain;

}
.user-container {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  margin-left: auto;
}
.nav-container {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  cursor: pointer;
  position: relative;
  color: white;
  margin-left: 20px;
}
.dropdown-caret {
  display: inline-block;
  width: 0;
  height: 0;
  vertical-align: middle;
  content: '';
  border-style: solid;
  border-width: 4px 4px 0;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent;
  border-top-color: #fff;
}
.user-info-container-show {
  left: auto !important;
  right: 0 !important;
}
.user-info-container {
  position: absolute;
  top: 95%;
  left: -99999px;
  z-index: 100;
  width: max-content;
  padding-top: 4px;
  padding-bottom: 4px;
  margin-top: 2px;
  list-style: none;
  background-clip: padding-box;
  border-radius: 6px;
  background: #fff;
  border-color: #d0d7de;
  padding: 5px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 8px 24px rgba(140, 149, 159, 0.2);
}
.user-info-container::before {
  top: -16px;
  right: 25px;
  left: auto;
  border: 8px solid transparent;
  border-bottom-color: #fff;
  position: absolute;
  display: inline-block;
  content: '';
}
.user-info-container span {
  padding: 10px 0 10px 10px;
  cursor: pointer;
}
.table-export-button{
  position: relative;
  top: -50px;
  left: 80px;
}

/* Header CSS */
.login-container {
  width: 400px;
  margin: 50px auto;
  background: #fff;
  padding: 30px;
}
.skill-tag {
  display: inline-block;
  background: #E1EBFB;
  color: #0062FF;
  border-radius: 5px;
  padding: 5px 8px;
  font-size: 12px;
  margin: 0 5px 5px 0;
}
.action-wrapper {
  display: flex;
  justify-content: flex-start;
}
.action-wrapper span {
  padding: 0 10px;
  cursor: pointer;
}
.box-header {
  display: block;
  padding: 7px 15px;
  line-height: 30px;
  position: relative;
  border-bottom: 1px solid #ddd;
}
.box-title {
  display: inline-block;
  font-size: 18px;
  font-weight: 500;
  margin: 0;
}
.react-bootstrap-table thead tr {
  background-color: #1380c0;
  color: #fff;
  font-size: 12px;
  line-height: 20px;
  border-bottom: 0 !important;
  font-weight: 600;
  padding: 10px 9px;
  letter-spacing: 0;
}
.box-body {
  padding: 20px;
}
.signup_box-body{
  padding: 90px;
}
.text-red {
  color: #ed1c24 !important;
}
.content_box {
  margin: 15px 0;
  background: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  border: 1px solid #ccc;
}

/* Bulk Upload Modal CSS */

#form-file-upload {
  height: 16rem;
  /* width: 28rem; */
  max-width: 100%;
  text-align: center;
  position: relative;
}

#input-file-upload {
  display: none;
}

#label-file-upload {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 2px;
  border-radius: 1rem;
  border-style: dashed;
  border-color: #cbd5e1;
  background-color: #f8fafc;
}

.upload-button {
  cursor: pointer;
  padding: 0.25rem;
  font-size: 1rem;
  border: none;
  font-family: 'Oswald', sans-serif;
  background-color: transparent;
}

.upload-button:hover {
  text-decoration-line: underline;
}
#label-file-upload.drag-active {
  background-color: #ffffff;
}
#drag-file-element {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}
/* Bulk Upload Modal CSS */
/* Table Pagination Custom CSS */
.react-bootstrap-table-pagination-list {
  justify-content: flex-end;
  display: flex;
  padding-right: 40px !important;
}
.react-bootstrap-table-pagination > div:first-child {
  padding-left: 20px;
}
input[type=file]::file-selector-button {
  background-color: #fff;
  color: #000;
  border: 0px;
  border-right: 1px solid #e5e5e5;
  padding: 10px 15px;
  margin-right: 20px;
  transition: .5s;
  margin-top:10px
}

input[type=file]::file-selector-button:hover {
  background-color: #eee;
  border: 0px;
  border-right: 1px solid #e5e5e5;
}
.signup_content_box {
  background: #ffffff;
  width: auto;
  height: 800px;
}
.buttonJob{
  margin-top: 10px;
}
textarea.form-control{
  min-height: calc(1.5em + 0.75rem + 144px) !important;
}
.card-button{
  float: right;
  margin-right: 10px;
}
.search-button{
  float: left;
}
.card-design{
  margin-bottom: 20px;
}
.card-sub{
  float: right;
}
.fontAwosome_change{
  margin-right: 10px;
}
.job-title{
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.job-description{
  color: #7C7C86;
}
.light-text{
  color: #7C7C86;
  font-weight: 400;
}
.skill-tag-card {
  display: inline-block;
  background: #E1EBFB;
  color: #0062FF;
  border-radius: 5px;
  padding: 5px 8px;
  font-size: 12px;
  margin-right: 5px;
  margin-top: 10px;
}
.center {
  margin: 0;
  position: absolute;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: -5px;
  left: 105%;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}